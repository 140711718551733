<template>
  <div>
    <el-form
      ref="form"
      label-width="80px"
      class="view pa24"
      label-position="left"
    >
      <el-form-item label="商品分类">
        <p style="color: $color-1">{{ detailsDate.goodsTypeName }}</p>
      </el-form-item>
      <hr
        style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 30px 0;
        "
      />

      <el-form-item label="商品名称"
        ><p>{{ detailsDate.goodsName }}</p>
      </el-form-item>

      <el-form-item label="商品库存"> {{ detailsDate.stockNum }}</el-form-item>

      <el-form-item label="商品价格">
        <span style="color: red">￥{{ detailsDate.price }}</span>
        <span style="color: red">~</span>
        <span style="color: red">￥{{ detailsDate.maxPrice }}</span>
      </el-form-item>

      <el-form-item label="商品规格">
        <div
          v-for="(item, index) in detailsDate.goodSpecModelList"
          :key="index"
        >
          <div>
            <span>{{ item.specName }}:</span>
            <div v-for="(a, b) in item.goodSpecModelList" :key="b">
              <span
                style="
                  padding: 5px 10px;
                  border: 1px solid rgb(81, 205, 203);
                  border-radius: 5px;
                  margin-left: 5px;
                "
                >{{ a.specAttribute }}
                <i style="color: red">{{ (a.price / 100).toFixed(2) }}元 </i>
              </span>
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item label="商品图片">
        <div v-for="(item, index) in detailsDate.goodPhoto" :key="index">
          <el-image
            style="
              width: 150px;
              height: 150px;
              border-radius: 5px;
              margin-right: 10px;
            "
            :src="item"
          ></el-image>
        </div>
      </el-form-item>
      <hr
        style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 30px 0;
        "
      />
      <el-form-item label="是否绑定探索">
        <el-radio-group v-model="detailsDate.isExplore">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="选择探索" v-if="detailsDate.isExplore == 1">
        <el-select
          v-model="detailsDate.exploreId"
          placeholder="请选择"
          @change="exploreChange"
        >
          <el-option
            v-for="item in applyList"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <hr
        style="
          background-color: #e4e4e4;
          border: none;
          height: 1px;
          margin: 30px 0;
        "
      />
      <el-form-item label="商品详情">
        <quill-editor v-model="detailsDate.goodsDetails"
      /></el-form-item>

      <el-form-item label-width="0px">
        <el-button
          size="medium"
          style="margin-left: 80px"
          @click="isShow = true"
          >预览</el-button
        >
      </el-form-item>
    </el-form>
    <prevGoods
      :isShow="isShow"
      :prod_imgs="detailsDate.goodPhoto"
      :prod-size="detailsDate.goodSpecModelList"
      :edit_html="detailsDate.goodsDetails"
      @close="prodClose"
    ></prevGoods>
  </div>
</template>

<script>
// @ts-ignore
import prevGoods from "./components/prevGoods";
//探索列表
import { exploreQueryPage } from "@/api/explore";
export default {
  components: {
    prevGoods,
  },
  data() {
    return {
      detailsDate: {},
      dialogImageUrl: "",
      dialogVisible: false,
      isShow: false,
      applyList: [],
    };
  },

  mounted() {
    this.getapplyList();
  },

  created() {
    this.detailsDate = JSON.parse(this.$route.query.details);
    this.detailsDate.isExplore = this.detailsDate.isExplore * 1;
    console.log(this.detailsDate, "this.detailsDate");
    this.detailsDate.goodPhoto = this.detailsDate.goodPhoto
      ? this.detailsDate.goodPhoto.split(",")
      : [];

    this.detailsDate.price = (this.detailsDate.price / 100).toFixed(2);
    console.log(this.detailsDate);
  },

  methods: {
    exploreChange(e) {
      this.applyList.forEach((el) => {
        if (el.id == e) {
          this.form.exploreUrl = el.url;
        }
      });
    },
    getapplyList() {
      let data = {
        pageNum: 1,
        pageSize: 100,
      };
      exploreQueryPage(data).then((res) => {
        this.applyList = res.data.records;
      });
    },
    prodClose() {
      this.isShow = false;
    },
  },
};
</script>
